<template>
  <div class="hold-transition">
    <div class="wrapper">
      <!-- Navbar -->
      <div class="content-wrapper">

      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
</style>